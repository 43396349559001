.product-image {
  margin: 1em 0;
  text-align: center;
}

.product-image img {
  height: 15vw;
}

.product-image-contain img {
  object-fit: contain;
}

.product-description {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
