body .p-button {
  position: relative;
  overflow: hidden;
  background: #000000;
  border-radius: 25px;
  padding: 1px 10px;
  border: #000 solid 2px;
  font-weight: bold;
  width: 100%;

  &:after {
    content: "";
    height: 200%;
    left: -230%;
    opacity: 0;
    position: absolute;
    top: -20%;
    width: 200%;
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
  }

  &:hover:after {
    left: -50%;
    opacity: 0.5;
    top: -50%;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-property: left, top, opacity;
    transition-timing-function: ease;
  }

  &:active:after {
    opacity: 0;
  }

  &:enabled:hover {
    background-color: #000;
    color: #ffffff;
    border-color: #000;
  }

  &.button-auto-width {
    width: auto;
    margin-right: 10px;
  }
}

.p-fileupload-choose input[type=file] {
  z-index: 999;
}

.p-button.p-fileupload-choose.p-component.p-button-text-icon-left {
  width: auto;
  margin-right: 10px;
}