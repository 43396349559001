.payment {
  display: flex;
  justify-content: space-around;
  margin: 1em 0;

  &__item {
    cursor: pointer;

    &.active {
      border-bottom: 2px solid #000000;
    }
  }

  &__img {
    max-width: 100%;
  }

  & .p-tabview.p-tabview-top .p-tabview-nav li a {
    background: #fff!important;
    height: 60px;
    padding: 8px;
  }
}

#card-iframe {
  border: none;
  width: 100%;
  height: 800px;
}