.wallet-grid {
  background: linear-gradient(to right,#008cf0 0%, #00cbf0 100%);
  padding: 14px 14px;
  display: flex;
  display: -ms-flexbox;
  align-content: space-between;
  flex-wrap: wrap;
  position: relative;
  color: #ffffff;
  border-radius: 5px;
  height: 196px;

  &__header {
    color: #333333;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 14px;
  }

  &.blocked {
    background: linear-gradient(to right,#f00000 0%, #f04200b0 100%);
  }

  & label {
    width: 100%;
    display: block;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
  }

  &__currencies {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    border: 3px solid #fff;
    width: 35px;
    height: 35px;
    text-align: center;
    padding: 3px 0;
    font-size: 18px;
  }

  &__balance {
    width: 100%;
    text-align: center;
  }

  &__balance-currency {
    opacity: .5;
    margin-right: 10px;
    font-weight: 600;
    font-size: 32px;
  }

  &__balance-count {
    font-weight: 700;
    font-size: 42px;
  }

  &__menu {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin-top: 1em;
  }

  &__button {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    cursor: pointer;
    padding: 3px 15px;
    margin-top: 10px;
    transition: .3s;
    color: #fff;

    &:hover {
      background: #fff;
      color: #000;
      transition: .3s;
    }
  }

  &__load {
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}