.sidenav---sidenav---_2tBP {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

nav.sidenav---sidenav---_2tBP {
  background: #000 !important;

  .nav-icon {
    color: #fff;
  }
}

.content-with-nav {
  padding-left: 65px;

  &.open {
    padding-left: 250px;

    @media only screen and (max-width: 600px) {
      padding-left: 65px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mobile-hidden {
    display: none;
  }
}
