body .p-tabview {

  &.p-tabview-top .p-tabview-nav li.p-highlight a {
    background-color: #000000!important;
    border: 1px solid #000000!important;
  }
}

@media only screen and (max-width: 600px) {
  .p-tabview.p-tabview-top > .p-tabview-nav li {
    width: 100%;

    & > a {
      width: 100%;
    }
  }
}

