.wallet-info {
  border: 1px solid #333333;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;

  &__back {
    color: #333333;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 14px;
    cursor: pointer;

    & i {
      vertical-align: -4px;
    }
  }

  &__header {
    font-weight: 700;
    font-size: 18px;
    text-decoration: underline;
    margin-bottom: 1.5em;
  }

  &__row {
    display: flex;
    margin-bottom: 1em;
  }

  &__label {
    font-weight: 700;
    font-size: 14px;
    margin-right: 10px;
  }

  &__value {
    font-weight: 500;
    font-size: 14px;
  }

  &__qr {
    text-align: center;
  }
}