body .p-dropdown {
  padding: 0 10px;
  border-radius: 20px;

  & .p-inputtext {
    height: auto;
    padding: 8px 0;
  }

  & .p-dropdown-trigger {
    border-radius: 25px;
    margin-right: 1px;
  }

  &.p-error {
    margin-bottom: 5px;
  }

  & .p-dropdown-label.p-placeholder {
    color: #898989;
  }
}