.merchant-messages {
  &-wrapper {
    position: relative;
  }
  &-icon {
    position: absolute;
    color: white;
    size: 1.5rem;
    top: 27px;
    right: 110px;
    font-size: 1.5rem;
  }
  &-counter {
    font-size: 1rem;
    font-weight: 700;
    position: absolute;
    right: -0.5rem;
    top: -0.75rem;
    text-align: center;
    line-height: 1.75rem;
    border-radius: 50%;
    background: blue;
    width: 1.5rem;
    height: 1.5rem;
  }
}

#overlayMessages:after,
#overlayMessages:before {
  left: unset;
  right: 1.25rem;
}
