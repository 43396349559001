@import "./variables";
@import "fontsProxima";
@import "./breakpoints";
@import "./spacing";
@import "button";
@import "input";
@import "switch";
@import "dropdown";
@import "calendar";
@import "tabwiev";
@import "popup";
@import "~primereact/resources/themes/nova-light/theme.css";
@import "~primereact/resources/primereact.min.css";
@import "~primeicons/primeicons.css";
@import "~primeflex/primeflex.css";

body, html {
  font-family: 'Proxima Nova Rg', sans-serif;
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  background-color: #ffffff;
}

body {
  .p-component {
    font-family: 'Proxima Nova Rg', sans-serif;
  }

  .p-flex {
    display: flex;
  }

  .p-float-label {
    margin-top: 10px;
    > label {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      padding-right: .25em;
    }
  }

  .text-center {
    text-align: center;
  }

  table {
    th {
      &.actionHeader {
        width: 8em;
        text-align: center;
      }
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.actionBody {
        text-align: center;
        overflow: visible
      }
    }
  }

  h1 {
    font-size: 36px;
    margin: 20px 0 12px;
  }
}
